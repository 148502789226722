.groupMemberModal {
    max-width: 150rem; /* Set your desired width */
}

.post-notificaion-modal {
    max-width: 40rem; /* Adjust the width as needed */
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.post-notificaion-modal.slide-out {
    animation: slideOut 0.35s ease-in-out forwards;
}
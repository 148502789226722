.input-wrapper {
    width: 250px;
    height: 1.9rem;
    border: none;
    border-radius: 25px;
    padding: 0 15px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
  }
  
  input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.25rem;
    width: 100%;
    margin-left: 5px;
  }
  
  input:focus {
    outline: none;
  }
  
  #search-icon {
    color: 	#D8D8D8;
  }

  .results-list {
    width: 230px;
    position: absolute;
    background-color: white;
    display: flex;
    z-index: 1000;
    top: 32px;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: auto;
  }

  .results-list-chat {
    width: 230px;
    position: absolute;
    background-color: white;
    display: flex;
    z-index: 1000;
    margin-left: 10px;
    bottom: calc(142px + 10px); /* Adjust the value as needed */
    flex-direction: column-reverse;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    /* margin-top: 1rem; */
    max-height: 300px;
    overflow-y: auto;
  }  

  .search-result {
    padding: 0px 10px;
  }
  
  .search-result:hover {
    background-color: #efefef;
  }

  .search-bar-container {
    width: 20%;
    right: 0;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }

.input-wrapper input {
    margin-top: -5px;
    margin-left: 5px;
}

.input-wrapper ::placeholder {
  color: #D3D3D3;
}
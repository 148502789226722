.grayBackground  {
  background-color: gray; /* Replace with your preferred shade of gray */
}

@media (min-width: 992px) {
  .col-lg-3 {
    min-width: 300px; /* Adjust the width as needed */
  }
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

  .custom-dropdown-toggle {
    width: auto; /* Adjust the width as needed */
  }

  .form-group.mb-2.btn-dropdown .custom-dropdown-menu {
    border: none;
    box-shadow: none;
  }
  
  .form-group.mb-2.btn-dropdown .custom-dropdown-menu.show {
    display: block;
  }


.btn-dropdown {
  min-width: 22rem;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background: white;
  border-radius: 4px;
  padding-left: 20px; }
  .btn-dropdown .heading {
    color: #fff; }
  .btn-dropdown .split {
    width: 60px;
    height: 60px;
    border-radius: 0 4px 4px 0; }
    .btn-dropdown .split .icon {
      color: #fff; }
  .btn-dropdown .dropdown-menu {
    border: none;
    background: #fff;
    -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
    padding: 0;
    left: 50% !important;
    -webkit-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    margin-top: 80px;
    min-width: 25rem; }
    @media (max-width: 767.98px) {
      .btn-dropdown .dropdown-menu {
        min-width: 22rem; } }
    .btn-dropdown .dropdown-menu:after, .btn-dropdown .dropdown-menu:before {
      content: ' ';
      display: block;
      border-style: solid;
      border-width: 0 .7em .8em .7em;
      border-color: transparent;
      position: absolute;
      left: 20px;
      z-index: -1; }
    .btn-dropdown .dropdown-menu:before {
      top: -.8em;
      border-bottom-color: rgba(0, 0, 0, 0.02); }
    .btn-dropdown .dropdown-menu:after {
      top: -.7em;
      border-bottom-color: white; }
    .btn-dropdown .dropdown-menu .dropdown-item {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      white-space: normal; }
      .btn-dropdown .dropdown-menu .dropdown-item:last-child {
        border: none; }
      .btn-dropdown .dropdown-menu .dropdown-item .cart {
        width: 60px;
        height: 60px;
        position: relative; }
      .btn-dropdown .dropdown-menu .dropdown-item .text {
        width: calc(100% - 60px); }
        .btn-dropdown .dropdown-menu .dropdown-item .text h4 {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8); }
        .btn-dropdown .dropdown-menu .dropdown-item .text .quantity {
          color: rgba(0, 0, 0, 0.3); }
      .btn-dropdown .dropdown-menu .dropdown-item.btn-link {
        color: #fa697c;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500; }
      .btn-dropdown .dropdown-menu .dropdown-item:hover {
        background: transparent; }

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

p {
  color: #b3b3b3;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }


a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a, a:hover {
    text-decoration: none !important; }

.content {
  height: 100vh; }

.request-modal {
  border-radius: 7px;
  overflow: hidden;
  background-color: transparent; }
  .request-modal .logo a img {
    width: 30px; }
  .request-modal .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 7px; }
    .request-modal .modal-content .modal-body {
      border-radius: 7px;
      overflow: hidden;
      background-color: #fff;
      padding-left: 0px;
      padding-right: 0px;
      -webkit-box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);
      box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9); }
      .request-modal .modal-content .modal-body h2 {
        font-size: 18px; }
      .request-modal .modal-content .modal-body p {
        color: #777;
        font-size: 14px; }
      .request-modal .modal-content .modal-body h3 {
        color: #000;
        font-size: 22px; }
      .request-modal .modal-content .modal-body .close-btn {
        color: #000; }
      .request-modal .modal-content .modal-body .promo-img {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%; }
        .request-modal .modal-content .modal-body .promo-img .price {
          top: 20px;
          left: 20px;
          position: absolute;
          color: #fff; }
  .request-modal .btn {
    border-radius: 30px; }
  .request-modal .warp-icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;
    background: rgba(62, 100, 255, 0.05);
    color: gray;
    border-radius: 50%; }
    .request-modal .warp-icon span {
      font-size: 40px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.form-request {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  padding-right: 0; }
  .form-request:active, .form-request:focus, .form-request:hover {
    border-bottom: 1px solid #000;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none; }

.custom-btn {
  border-radius: 4px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px; }
  .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px; }
  .close-btn span {
    color: #ccc; }
  .close-btn:hover span {
    color: #000; }

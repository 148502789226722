button {
    outline: none;
  }
  .custom-btn {
    width: 100px;
    height: 40px;
    border: 2px solid #000;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  
  /* 1 */
  .btn-1 {
    transition: all 0.3s ease;
  }
  .btn-1:hover {
     box-shadow:
     -7px -7px 20px 0px #fff9,
     -4px -4px 5px 0px #fff9,
     7px 7px 20px 0px #0002,
     4px 4px 5px 0px #0001;
  }

  .custom-btn[disabled] {
    cursor: not-allowed;
    border-color: #C8C8C8;
  }
#chatCon {
    z-index: 1000;
    width: 350px;
    position: fixed;
    bottom: 0%;
    right: 120px;
  }
  
  #chatCon .chat-box {
    width: 350px;
    height: 490px;
    border-radius: 25px;
    background-color: #eee;
  }
  
  #chatCon .chat-box .header {
    background-color: gray;
    padding: 10px;
    padding-bottom: 5px;
    border-radius: 20px 20px 0 0;
    color: #fff;
    font-size: 18px;
  }
  
  #chatCon .chat-box .msg-area {
    overflow-y: auto;
    height: 400px;
    padding: 15px;
  }

  #chatCon .chat-box .msg-area::-webkit-scrollbar {
    width: 1px; /* Width of the scrollbar */
  }
  
  #chatCon .chat-box .msg-area::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
  }
  
  #chatCon .chat-box .msg-area::-webkit-scrollbar-thumb {
    background: #DCDCDC; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  }
  
  #chatCon .chat-box .msg-area::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }  
  
  #chatCon .chat-box .msg-area .left span {
    display: inline-block;
    white-space: normal;
    font-size: 13.5px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    text-align: left;
    padding-bottom: 5px;
    background-color: #ddd;
    word-wrap: break-word; /* Allow long words to break */
    color: black;
    max-width: 180px;
  }
  
  #chatCon .chat-box .msg-area .right {
    text-align: right;
  }
  
  #chatCon .chat-box .msg-area .right span {
    color: black;
    display: inline-block;
    font-size: 13.5px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ddd;
    text-align: left;
    max-width: 160px;
  }
  
  #chatCon .chat-box .footer input {
    border: 1px solid #fff;
    padding: 10px;
    width: 500px;
    font-size: 14px;
    border-radius: 20px;
    background-color: black;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .blink-animation {
    animation: blink 0.5s ease-in-out 2 forwards;
  }
  
  #chatCon .pop {
    width: 100%;
    height: 25%;
    cursor: pointer;
  }
  
  #chatCon .pop p {
    text-align: right;
  }
  
  #chatCon .pop img {
    border-radius: 50%;
    width: 25%;
  }
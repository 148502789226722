body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    
    font-weight: 300; }

  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

  
  .content {
    padding: 7rem 0; }
  
  h2 {
    font-size: 20px;
    }
  
  .custom-table {
    min-width: 900px; }
    .custom-table thead tr, .custom-table thead th {
      padding-bottom: 15px;
      border-top: none;
      border-bottom: none !important;
      /* color: #fff; */
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .2rem; }
    .custom-table tbody th, .custom-table tbody td {
      padding-bottom: 20px;
      padding-top: 20px;
      font-weight: 400;
      border: none; }
      .custom-table tbody th small, .custom-table tbody td small {
        /* color: rgba(255, 255, 255, 0.3); */
        font-weight: 300; }
      .custom-table tbody th .more, .custom-table tbody td .more {
        /* color: rgba(255, 255, 255, 0.3); */
        font-size: 11px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .2rem; }
    .custom-table tbody tr {
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
      .custom-table tbody tr:hover td, .custom-table tbody tr:focus td {
        color: black;
    }
        /* .custom-table tbody tr:hover td a, .custom-table tbody tr:focus td a {
          color: #fdd114; }
        .custom-table tbody tr:hover td .more, .custom-table tbody tr:focus td .more {
          color: #fdd114; } */
    .custom-table .td-box-wrap {
      padding: 0; }
    .custom-table .box {
      background: #fff;
      border-radius: 4px;
      margin-top: 15px;
      margin-bottom: 15px; }
      .custom-table .box td, .custom-table .box th {
        border: none !important; }
  

.form-control{
  /* border-radius: 7px; */
  /* border: 1.5px solid #E3E6ED; */
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
input.form-control:focus{
  box-shadow: none;
  border: 1.5px solid #E3E6ED;
  /* background-color: #F7F8FD; */
  letter-spacing: 1px;
}
.btn-search{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #5878FF!important;
  padding-bottom: 8px;
  /* border-radius: 7px; */
}
.btn-search:focus{
  box-shadow: none;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  bottom: .5em;
}
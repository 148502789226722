.btn {
    cursor: pointer;
    border-radius: 40px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px; }
    .btn:hover, .btn:active, .btn:focus {
      outline: none; }
    .btn.btn-primary {
      background: #3e64ff;
      border: 1px solid #3e64ff;
      color: #fff; }
      .btn.btn-primary:hover {
        border: 1px solid #3e64ff;
        /* background: transparent; */
        color: #3e64ff; }
      .btn.btn-primary.btn-outline-primary {
        border: 1px solid #3e64ff;
        background: transparent;
        color: #3e64ff; }
        .btn.btn-primary.btn-outline-primary:hover {
          border: 1px solid transparent;
          background: #3e64ff;
          color: #fff; }

  
  .modal-content {
    border: none;
    position: relative;
    padding: 0 !important;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    background: #fff; }
    .modal-content .modal-header {
      position: relative;
      padding: 0;
      border: none; }
    .modal-content button.close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      margin: 0;
      width: 40px;
      height: 40px;
      z-index: 1; }
    .modal-content .modal-body {
      border: none; }
      .modal-content .modal-body label {
        text-transform: uppercase;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3); }
      .modal-content .modal-body .forgot {
        color: rgba(0, 0, 0, 0.3);
        text-decoration: underline; }
  

  
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');

.my-modal {
    width: 600px;
    max-width: 50vw;
} 

textarea{
    font-family: monospace;
}

textarea::placeholder {
    color: '#A8A8A8';
    font-family: monospace;
    line-height: 40px;
    font-size: 0.8em;
    font-weight: 500;
}

input::placeholder {
    color: '#A8A8A8';
    font-family: monospace;
    line-height: 40px;
    font-size: 0.8em;
    font-weight: 500;
}

textarea:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }